import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import 'flexmonster/flexmonster.css'
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import 'semantic-ui-less/semantic.less'
import './semantic-ui/semantic';
import './semantic-ui/semantic.less';

ReactDOM.render(<App />, document.getElementById("root"));

// // message event handler (e is event object)
// function handleMessageFromParent(e) {
//     // Reference to element for data display
//     console.log( "Handling message." );
//     console.log( e.data );
//     alert( e.data );
//     // Send reply to source of message
//     e.source.postMessage('Message received from parent', e.origin);
//
// }

// if ( window.addEventListener ) {
//     window.addEventListener('message', handleMessageFromParent, false);
// } else if ( window.attachEvent ) { // ie8
//     window.attachEvent('onmessage', handleMessageFromParent);
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
