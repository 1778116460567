import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Dropdown, Modal } from "semantic-ui-react";
import DatePicker from 'react-datepicker/dist/react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './NewReportModal.less';
import { API_PATHS, EMPTY_REPORT } from "../../constants";
import { standardGetFetchOptions } from "../../utils/FetchUtils";
import { getModuleIdFromPath } from "../../utils/GeneralUtils";

const NewReportModal = ( {
                             title,
                             comment,
                             open,
                             onClose,
                             onGo,
                             commentHeader,
                             cancelLabel
                         } ) => {
    //console.log( "new report modal" );
    const DATASET_OPTIONS = [
        {
            key: 'placeholder-key',
            value: 'placeholder-value',
            text: '(Loading dataset list...)'
        }
    ]

    const PLACEHOLDER_LOADING = "Loading datasets...";
    const PLACEHOLDER_LOADED = "(Select a dataset)";

    const [ datasets, setDatasets ] = useState();
    const [ datasetOptions, setDatasetOptions ] = useState( DATASET_OPTIONS );
    const [ isNotLoaded, setIsNotLoaded ] = useState( true );
    const [ error, setError ] = useState();
    const [ placeholderText, setPlaceholderText ] = useState( PLACEHOLDER_LOADING );
    const [ hasFilters, setHasFilters ] = useState( false );
    const [ filters, setFilters ] = useState();
    const [ filterValues, setFilterValues ] = useState( [ new Date( '01/01/2020' ), new Date( '12/01/2020' ) ] );
    const [ report, setReport ] = useState( EMPTY_REPORT );
    const [ disablePrimary, setDisablePrimary ] = useState( true );
    //const [ selectedDataset, setSelectedDataset ] = useState();

    let selectedDataset;

    const handleChangedDataset = function ( e, { value } ) {

        if( !datasets )
            return;
        // console.log( "Dataset changed." );

        setDisablePrimary( false );

        let index = value;

        selectedDataset = datasets[ index ];

        let filters = selectedDataset.filters;

        let localHasFilters = false;

        if ( filters && filters.length > 0 ) {
            // console.log( "we have filters and filter length is greater than 0" );
            localHasFilters = true;
        } else {
            // console.log( "we do not have filters" );
            localHasFilters = false;
        }

        // console.log( "hasFilters: ");
        // console.log( hasFilters );

        if( localHasFilters ) {
            // console.log( "We have filters - so set up defaults." );
            var filterValuesArray = [];
            for ( var i = 0; i < filters.length; i++ ) {
                var filter = filters[ i ];

                var defaultFunc = new Function( filter.defaultValue );
                var defaultValue = defaultFunc();
                filterValuesArray[ i ] = defaultValue;
                filter.filterValue = defaultValue;
            }

            setFilterValues( filterValuesArray );
            setFilters( filters );
        } else {
            setFilterValues( null );
            setFilters( null );
        }
        report.dataset = selectedDataset;
        setHasFilters( localHasFilters );
    };

    function fetchDatasets() {
        let fullPath = process.env.REACT_APP_CONNECTOR_HOST + API_PATHS.DATA + "?moduleId=" + getModuleIdFromPath();

        fetch( fullPath, standardGetFetchOptions() )
            .then( res => res.json() )
            .then(
                ( result ) => {
                    setDatasets( result.datasets );

                    let options = new Array( 0 );

                    result.datasets.forEach( ( item, index ) => {

                        let option = {
                            key: index,
                            value: index,
                            text: item.descriptiveName
                        }

                        options.push( option );
                    } );

                    setDatasetOptions( options );
                    setIsNotLoaded( false );
                    setPlaceholderText( PLACEHOLDER_LOADED );

                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                ( error ) => {
                    console.log( error );
                    setIsNotLoaded( false );
                    setError( error );
                }
            )
    }

// similar to componentDidMount()
    useEffect( () => {
        fetchDatasets();
    }, [] )

    const handleDate = function( date, filterItem, idx ) {
        //console.log( "Got date." );
        // console.log( date );
        // console.log( filterItem );
        //filterItem.filterValue = date.toISOString().substring( 0, 10 );
        filterItem.filterValue = date;
        // console.log( filterItem );
        var newArray = [ ...filterValues ];
        newArray[ idx ] = date;
        setFilterValues( newArray );
    }

    const handleText = function( data, filterItem, idx ) {
        // console.log( "text box #" + idx + " changed " );
        // console.log( "data: " + data.target.value );

        filterItem.filterValue = data.target.value;
        // console.log( filterItem );

        var newArray = [ ...filterValues ];
        newArray[ idx ] = data.target.value;
        setFilterValues( newArray );
    }

    return (
        <Modal open={open} onClose={onClose} dimmer='inverted' size='tiny' className="NewReportModal">
            <Modal.Header className="ui header">
                New Report
            </Modal.Header>

            <Modal.Content>
                <div style={{ marginBottom: "10px" }}>
                    <Dropdown
                        placeholder={placeholderText}
                        fluid
                        selection
                        options={datasetOptions}
                        disabled={isNotLoaded}
                        onChange={handleChangedDataset}
                    />
                </div>
                {hasFilters && filters &&
                <div className={"ui input filters"} style={{ display: "block" }}>
                    <h3>Filter by:</h3>
                    <div style={{ display: "inline-block" }}>
                        {filters.map( ( item, idx ) =>
                            <div key={idx} className={"filter"} style={{ display: "flex" }}>
                                <div className={"filter-label"}>{item.descriptiveName}<span style={{color:"#b31200"}}>*</span></div>
                                {item.type === "Date" &&
                                <DatePicker onChange={date => handleDate( date, item, idx )}
                                            selected={filterValues[ idx ]}/>
                                }

                                {item.type === "Text" &&
                                <input type="text" onChange={ ( e, data ) => handleText( e, item, idx )}
                                       value={filterValues[ idx ] } ></input>
                                }
                            </div>
                        )}
                    </div>
                </div>
                }

                {/*<VerticalSpacing/>*/}
                <div className={"ui input"}>

                </div>
            </Modal.Content>

            {/*<VerticalSpacing/>*/}

            <Modal.Actions>
                <div className="field">
                    <Button onClick={onClose}>
                        Cancel
                    </Button>
                    <Button disabled={disablePrimary} primary onClick={() => onGo( report )}>Go</Button>
                </div>
            </Modal.Actions>
        </Modal>
    );
};

NewReportModal.propTypes = {
    title: PropTypes.string,
    comment: PropTypes.string,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    commentHeader: PropTypes.string
};

export default NewReportModal;
