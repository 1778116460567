import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Dropdown, Modal } from "semantic-ui-react";
import "react-datepicker/dist/react-datepicker.css";
import './SaveReportModal.less';

const SaveReportModal = ({
                             title,
                             comment,
                             open,
                             onCloseClicked,
                             onGo,
                             commentHeader,
                             cancelLabel,
                             suggestedFileName
                         } ) => {


    const PLACEHOLDER_LOADING = "Enter filename...";

    const [ filename, setFilename ] = useState(suggestedFileName);

    useEffect( () => {
        //
    }, [] )

    const handleFilenameChange = function( e ) {
        // console.log( "text box changed: " + e.target.value );
        // console.log( "event: " + e );

        setFilename( e.target.value );
    }

    return (
        <Modal open={open} onClose={onCloseClicked} dimmer='inverted' size='tiny'>
            <Modal.Header className="ui header">
                Enter name of report to save
            </Modal.Header>

            <Modal.Content>
                <div className={"ui input"} style={{width:"100%"}}>
                    <input type="text" placeholder={PLACEHOLDER_LOADING} value={filename} onChange={handleFilenameChange} />
                </div>
            </Modal.Content>

            <Modal.Actions>
                <div className="field">
                    <Button onClick={onCloseClicked}>
                        Cancel
                    </Button>
                    <Button disabled={!filename}  primary onClick={() => onGo( filename )}>Save</Button>
                </div>
            </Modal.Actions>
        </Modal>
    );
};

SaveReportModal.propTypes = {
    title: PropTypes.string,
    comment: PropTypes.string,
    visible: PropTypes.bool,
    onCloseClicked: PropTypes.func,
    commentHeader: PropTypes.string
};

export default SaveReportModal;
