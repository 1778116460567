import React, { useContext, useEffect, useState } from "react";
import './SilentTokenRefresh';
import { useInterval } from "../../utils/GeneralUtils";

function SilentTokenRefresh( props ) {
    const [showIframe, setShowIframe] = useState( false );
    const [pauseTimer, setPauseTimer] = useState( false );

    const intervalRef = useInterval(() => {
        setShowIframe( !showIframe );
    }, pauseTimer ? null : 30000 );

    return (
        <div style={{display:"block"}}>This should never be displayed.
            {showIframe &&
            <div>
                This should not be seen.
                <iframe src="https://signin.ft.cayuse.com/authorize?redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fhandoff%2FIRB"/>
            </div>
            }
            </div>
    );
}

export default SilentTokenRefresh;
