export const PAGE_ROUTES = {
    REPORTS: "/reports",
    REPORT_VIEWER: "/reportviewer",
    HANDOFF: "/handoff",
    ONELOGIN: "/onelogin"
}

export const API_PATHS = {
    REPORTS: "/reports",
    DATA: "/data"
}

export const LOCAL_STORAGE_KEYS = {
    AUTH_TOKEN: "fk_accessToken",
    MODULE_ID: "moduleId"
}

export const EMPTY_REPORT = {
    reportName: "New Report",
    dataset: {},
    reportBody: {
        dataSource: {
            type: 'csv',
            filename: 'http://localhost:8080/'
        },
        options: {
            grid: {
                type: "flat",
                showTotals: "off",
                showGrandTotals: "off"
            }
        }
    }
};
