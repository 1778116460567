import React, { useState } from 'react';

const ModuleContext = React.createContext(["", (() => "")] );

const ModuleContextProvider = (props) => {
    const [state, setState] = useState("" );
    return (
        <ModuleContext.Provider value={[state, setState]}>
            {props.children}
        </ModuleContext.Provider>
    );
}

export { ModuleContext, ModuleContextProvider };
