import React, { useState, useEffect, useContext } from "react";
import jwtDecode from "jwt-decode";

import { isNonEmptyString } from "./GeneralUtils";

import queryString from "query-string";

const CAYUSE_LOGOUT_URL = `https://${process.env.REACT_APP_AUTH_DOMAIN}/logout`;
const CAYUSE_LOGIN_URL = `https://${process.env.REACT_APP_AUTH_DOMAIN}/authorize`;
const ACCESS_TOKEN = "fk_accessToken";
const ACCESS_TOKEN_EXP = "fk_accessTokenExp";

export const AuthContext = React.createContext();

export const useAuth = () => useContext(AuthContext);

export const loginWithRedirect = ({ tenantId, isGuest }) => {
    const encodedUri = encodeURIComponent(window.location.href.split(/[?#]/)[0]);
    let url = `${CAYUSE_LOGIN_URL}?redirect_uri=${encodedUri}&response_type=TOKEN`;

    if (isNonEmptyString(tenantId)) {
        url += `&tenant_id=${tenantId}`;
    }

    if (isGuest) {
        url += "&guest=true";
    }

    window.location.replace(url);
};

export function OneLoginRerouter (props ) {
    // console.log( "props: " + JSON.stringify( props ) );

    let params = queryString.parse( props.location.search );
    let tenantId = params[ 'tenant_id' ];
    let moduleId = params[ 'moduleId' ];

    let currentUrl = window.location;
    let callbackUrl = currentUrl.protocol + "//"
        + currentUrl.host + "/handoff/"
        + ( moduleId ? moduleId : "FM" );

    let oneLoginUrl =  process.env.REACT_APP_ONE_LOGIN + "/login" //"https://signin.ft.cayuse.com/login"
        + "?tenant_id=" + tenantId //b58efab0-b06c-4f98-b15f-87fc35823607"
        + "&redirect_uri=" + callbackUrl //https://localhost:3000/handoff/" + ( moduleId ? moduleId : "SP" )
        + "&response_type=token";

    window.location.href = oneLoginUrl;
    return null;
}

export const AuthProvider = ({
    children,
    tokenRefreshUrl,
    isGuest
}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [_, setAccessTokenExpires] = useState(null);
    const [backgroundTokenCheck, setBackgroundTokenCheck] = useState(false);
    const [hiddenTokenRefreshElement, setHiddenTokenRefreshElement] = useState(null);


    const retrieveAccessTokenFromLocationHash = (location, isBackgroundIframe) => {
        const hash = location.hash;
        let newAccessToken;
        if (hash) {
            const tokens = hash.substring(1, hash.length).split("&");
            for (let i in tokens) {
                if (tokens[i]) {
                    const keyValuePair = tokens[i].split("=");
                    if (keyValuePair[0].length > 1) {
                        if (keyValuePair[0] === "access_token") {
                            newAccessToken = keyValuePair[1];
                            localStorage.setItem(ACCESS_TOKEN, newAccessToken);
                            const decodedToken = jwtDecode(newAccessToken);
                            localStorage.setItem(ACCESS_TOKEN_EXP, decodedToken.exp);
                            setAccessToken(newAccessToken);
                            setIsAuthenticated(true);
                            if (!isBackgroundIframe) {
                                window.history.replaceState({}, document.title, location.pathname);
                            }
                        }
                    }
                }
            }
        }
    };

    const isAccessTokenMismatchedOrExpired = () => {
        const accessTokenFromStorage = localStorage.getItem(ACCESS_TOKEN);

        if (!accessTokenFromStorage) {
            return true;
        }

        const accessTokenExpirationFromStorage = localStorage.getItem(ACCESS_TOKEN_EXP);
        let timeUntilTokenExpires;

        if (accessTokenExpirationFromStorage) {
            timeUntilTokenExpires = accessTokenExpirationFromStorage - Math.floor(new Date().getTime() / 1000);
        } else {
            const decodedToken = jwtDecode(accessTokenFromStorage);
            timeUntilTokenExpires = decodedToken.exp - Math.floor(new Date().getTime() / 1000);
        }

        return accessTokenFromStorage !== accessToken || timeUntilTokenExpires < 60;
    };

    useEffect(() => {
        const initAuth = async () => {
            if (window.location.hash.includes("access_token=")) {
                retrieveAccessTokenFromLocationHash(window.location, false);
                setLoading(false);
            } else if (localStorage.getItem(ACCESS_TOKEN)) {
                if (isAccessTokenMismatchedOrExpired()) {
                    setIsAuthenticated(false);
                    setLoading(false);
                } else {
                    const accessTokenFromStorage = localStorage.getItem(ACCESS_TOKEN);
                    const accessTokenExpirationFromStorage = localStorage.getItem(ACCESS_TOKEN_EXP);
                    setAccessToken(accessTokenFromStorage);
                    setAccessTokenExpires(accessTokenExpirationFromStorage);
                    setIsAuthenticated(true);
                    setLoading(false);
                }
            } else {
                setIsAuthenticated(false);
                setLoading(false);
            }
        };

        initAuth();
    }, []);

    useEffect(() => {
        if (accessToken) {
            const token = localStorage.getItem(ACCESS_TOKEN);
            if (isNonEmptyString(token) && token === accessToken) {
                const user = jwtDecode(token);
                setIsAuthenticated(true);
                setUser(user);

                let host = window.location.origin;
                setBackgroundTokenCheck(Date.now());
            } else {
                loginWithRedirect({ tenantId: user.tenantId, isGuest });
            }
        }
    }, [accessToken, isGuest]);


    const hiddenRefreshURL = () => {
        const refreshUrl = isNonEmptyString(tokenRefreshUrl)
            ? tokenRefreshUrl
            : `${window.location.protocol}//${window.location.host}/token_refresh.html`;
        const encodedUri = encodeURIComponent(refreshUrl);
        let url = `${CAYUSE_LOGIN_URL}?redirect_uri=${encodedUri}&response_type=TOKEN`;

        if (isNonEmptyString(user?.tenantId)) {
            url += `&tenant_id=${user.tenantId}`;
        }

        return url;
    };

    const setupHiddenTokenRefreshElement = () => {
        const iFrame = document.createElement("iframe");
        iFrame.id = "accessTokenRefresh";
        iFrame.style.display = "none";
        iFrame.onload = function (event) {
            try {
                retrieveAccessTokenFromLocationHash(event.currentTarget?.contentWindow?.location, true);
                setIsAuthenticated(true);
            } catch (error) {
                setAccessToken(null);
                setAccessTokenExpires(null);
                window.localStorage.removeItem(ACCESS_TOKEN);
                setIsAuthenticated(false);
            }
        };

        return iFrame;
    };

    const checkTokenInBackground = () => {
        if (isAccessTokenMismatchedOrExpired()) {
            if (hiddenTokenRefreshElement) {
                hiddenTokenRefreshElement.src = hiddenRefreshURL();
            } else {
                setHiddenTokenRefreshElement(setupHiddenTokenRefreshElement());
            }
        }
        setBackgroundTokenCheck(Date.now());
    };

    useEffect(() => {
        if (hiddenTokenRefreshElement) {
            hiddenTokenRefreshElement.src = hiddenRefreshURL();
            document.body.appendChild(hiddenTokenRefreshElement);
        }
    }, [hiddenTokenRefreshElement]);

    useEffect(() => {
        if (backgroundTokenCheck) {
            const timeout = setTimeout(function () {
                checkTokenInBackground();
            }, 5000);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [backgroundTokenCheck]);

    useEffect(() => {
        window.addEventListener("header:navigationChange", () => {
            setAccessToken(null);
            setAccessTokenExpires(null);
            window.localStorage.removeItem(ACCESS_TOKEN);
            window.location.href = CAYUSE_LOGOUT_URL;
        });
    }, []);

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                loading,
                user,
                loginWithRedirect: (...p) => loginWithRedirect({
                    ...p,
                    isGuest: p.isGuest || isGuest
                })
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
