import React, { useState } from "react";
// import FlexmonsterReact from "react-flexmonster";
import './Reports.less';
// import {Link} from "react-router-dom";
import ReportsList from "../ReportsList";
import { Button, Icon } from "semantic-ui-react";
import NewReportModal from "../NewReportModal";
import { PAGE_ROUTES } from "../../constants";
import { has } from "../../utils/GeneralUtils";
import { useHistory } from 'react-router-dom'

function Reports( props ) {

    // console.log( props );

    let moduleId;

    if ( has( props, "match.params.moduleId" ) )
        moduleId = props.match.params.moduleId;

    // console.log( "The Module ID is: " + moduleId );

    const [ showNewReport, setShowNewReportModal ] = useState( false );

    const handleNewReport = function () {
        setShowNewReportModal( true );
    }

    const handleNewReportClose = function () {
        setShowNewReportModal( false );
    }

    const history = useHistory();

    const handleOnGo = function ( data ) {
        // console.log( "handleOnGo called with data: " + JSON.stringify( data ) );
        setShowNewReportModal( false );

        history.push( {
            pathname: "/" + moduleId + PAGE_ROUTES.REPORTS + "/" + "New Report",
            data: data
        } );
    }

    return (
        <div className="Reports">
            <div className="header">
                <div className="reportName">Saved Reports</div>
                <div className="buttons">
                    <Button primary onClick={handleNewReport}><Icon name='plus'></Icon>New Report</Button>
                </div>
            </div>

            <div className="contents">
                <div className="anotherDiv">
                </div>

                <ReportsList moduleId={moduleId} />

            </div>

            { showNewReport &&
                <NewReportModal open={showNewReport} onClose={handleNewReportClose} onGo={handleOnGo}/>
            }

        </div> );
}

export default Reports;
