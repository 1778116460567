import { PAGE_ROUTES } from "../constants";
import { useEffect, useRef } from "react";

export function has( obj, key ) {
    return key.split(".").every(function(x) {
        if(typeof obj != "object" || obj === null || ! x in obj)
            return false;
        obj = obj[x];
        return true;
    });
}

const PAGE_ROUTES_HASH = {};
PAGE_ROUTES_HASH[ PAGE_ROUTES.REPORTS.split('/')[1] ] = true;
PAGE_ROUTES_HASH[ PAGE_ROUTES.HANDOFF.split( '/' )[1] ] = true;
PAGE_ROUTES_HASH[ PAGE_ROUTES.REPORT_VIEWER.split( '/' )[1] ] = true;
PAGE_ROUTES_HASH[ PAGE_ROUTES.HANDOFF.split( '/' )[1] ] = true;

export function getModuleIdFromPath() {
    let pathname = window.location.pathname;
    let moduleId = pathname.split( '/' )[1];
    if( !moduleId )
        return '';

    if( PAGE_ROUTES_HASH[moduleId] )
        return '';

    return moduleId;
}

export function useInterval(callback, delay) {
    const intervalRef = useRef();
    const callbackRef = useRef(callback);

    // Remember the latest callback:
    //
    // Without this, if you change the callback, when setInterval ticks again, it
    // will still call your old callback.
    //
    // If you add `callback` to useEffect's deps, it will work fine but the
    // interval will be reset.

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    // Set up the interval:

    useEffect(() => {
        if (typeof delay === 'number') {
            intervalRef.current = window.setInterval(() => callbackRef.current(), delay);

            // Clear interval if the components is unmounted or the delay changes:
            return () => window.clearInterval(intervalRef.current);
        }
    }, [delay]);

    // Returns a ref to the interval ID in case you want to clear it manually:
    return intervalRef;
}

export const isNonEmptyString = value => typeof value === "string" && value.length > 0;
