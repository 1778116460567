import React, { /*useState, useEffect*/ } from "react";
import './Breadcrumb.less';
import {Link} from "react-router-dom";
import {PAGE_ROUTES} from "../../constants";
import {Icon} from "semantic-ui-react";

function Breadcrumb( {moduleId} ) {

    return (
        <div className="Breadcrumb" >
            <div className="src-components-SectionHeader-styles___layout">
                <div>
                        <div className="ui breadcrumb" style={{marginLeft:"2%", marginTop:"0.65em"}}>
                            <div className="section"><Link to={ "/" + moduleId + PAGE_ROUTES.REPORTS } ><Icon name="home"></Icon> Saved Reports</Link></div>
                            {/*<div className="divider">&nbsp;/&nbsp; </div>*/}
                            {/*<div className="section">Salary Coverage Report</div>*/}
                        </div>
                    </div>
                </div>
        </div>);
}

export default Breadcrumb;
