import React, { useContext } from "react";
import './Handoff';
import { Redirect, useHistory } from "react-router-dom";
import { API_PATHS, LOCAL_STORAGE_KEYS, PAGE_ROUTES } from "../../constants";
import { ModuleContext } from "../../context/ModuleContext";
import { TokenContext } from "../../context/TokenContext";
import { getStandardHeaders, standardGetFetchOptions, standardHeadFetchOptions } from "../../utils/FetchUtils";
import { getModuleIdFromPath } from "../../utils/GeneralUtils";

//import { Redirect } from "react-router-dom/es/Redirect";

function Handoff( props ) {

    // console.log( "props:" + JSON.stringify( props ) );

    // eslint-disable-next-line no-unused-vars
    const [ moduleId, setModuleId ] = useContext( ModuleContext );
    // eslint-disable-next-line no-unused-vars
    const [ token, setToken ] = useContext( TokenContext );

    // Get a handle to the history object.
    // eslint-disable-next-line no-unused-vars
    const history = useHistory();

    let search = props.location.search; //window.location.search;
    let searchParams = new URLSearchParams( search );

    // This could come from the login service callback.
    let idTokenFromParam = searchParams.get( 'id_token' );
    let hasToken = false;
    if ( idTokenFromParam ) {
        localStorage.setItem( LOCAL_STORAGE_KEYS.AUTH_TOKEN, idTokenFromParam );
        setToken( idTokenFromParam );
        hasToken = true;
    }

    // OneLogin prepends a hash to the id_token param, so deal with that.
    let hash = props.location.hash;
    if ( hash ) {
        // console.log( "hash is present.  We may have a token." );
        let searchString = hash.replace( "#", "" );
        let hashSearchParams = new URLSearchParams( searchString );
        let possibleToken = hashSearchParams.get( 'id_token' );
        if ( possibleToken ) {
            // console.log( "Likely to contain a token." );
            localStorage.setItem( LOCAL_STORAGE_KEYS.AUTH_TOKEN, possibleToken );
            setToken( possibleToken );
            hasToken = true;
        }
    }

    let match = props.match;
    let params = match.params;

    // console.log( 'params:' + JSON.stringify( params ) );

    let moduleIdFromPath = params.moduleId;
    // console.log( "moduleIdFromPath: " + moduleIdFromPath );

    let moduleIdFromParam = searchParams.get( 'moduleId' );
    // console.log( "moduleIdFromParam:" + moduleIdFromParam );

    let moduleIdToUse = moduleIdFromPath ? moduleIdFromPath : moduleIdFromParam;
    // console.log( "moduleIdTouse: " + moduleIdToUse );

    if ( moduleIdToUse ) {
        // console.log( 'Module id is not empty' );
        localStorage.setItem( LOCAL_STORAGE_KEYS.MODULE_ID, moduleIdToUse );
        setModuleId( moduleIdToUse );

        // console.log( "moduleId was set to " + moduleIdToUse );

        //history.push( PAGE_ROUTES.REPORTS );

        let fullPath = process.env.REACT_APP_CONNECTOR_HOST + API_PATHS.DATA + "?moduleId=" + moduleIdToUse;

        if( hasToken )
            fetch( fullPath, standardHeadFetchOptions() )
                .then( res => console.log( "Warming up cache." ) );

        // console.log( "Redirecting" );

        return ( <div><Redirect to={ "/" + moduleIdToUse + PAGE_ROUTES.REPORTS }/></div> );
    }

    return (
        <div></div>
        // <div className="Handoff">
        //     {( moduleIdToUse ) ||
        //     <span>No module ID specificed.</span>
        //     }
        // </div>
    );
}

export default Handoff;
