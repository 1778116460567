import './MainView.less'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Breadcrumb, Handoff, Reports, ReportViewer } from "../index";
import { LOCAL_STORAGE_KEYS, PAGE_ROUTES } from "../../constants";
import React, { useContext, useEffect, useState } from "react";
import { TokenContext } from "../../context/TokenContext";
import { OneLoginRerouter } from "../../utils/AuthUtils";
import preval from 'preval.macro'
import SilentTokenRefresh from "../SilentTokenRefresh";

let jwtDecode = require( "jwt-decode" );

function MainView() {

    let pathname = window.location.pathname;
    let moduleId = pathname.split( '/' )[1];
    if( !moduleId )
        moduleId='';

    // const [moduleId, setModuleId] = useContext( ModuleContext );
    const [ contexttoken, setContextToken ] = useContext( TokenContext );

    const [ token, setToken ] = useState( localStorage.getItem( LOCAL_STORAGE_KEYS.AUTH_TOKEN ) );
    //const [ moduleId, setModuleId ] = useState( localStorage.getItem( LOCAL_STORAGE_KEYS.MODULE_ID ) );
    const [ refreshMe, setRefreshMe ] = useState( "" );

    // if( initToken )
    //     setToken( initToken );

    function inIframe() {
        try {
            return window.self !== window.top;
        } catch ( e ) {
            return true;
        }
    }

    const handleMessageFromParent = ( e ) => {
        // Reference to element for data display
        // console.log( "MainView.js received message: " + e.data );

        let token = '';
        if ( e.data.startsWith( "{" ) ) {
            // It looks like we have a JSON object, so check to see if it's ours.

            let obj = JSON.parse( e.data );
            if ( !( 'cayuse_login_token' in obj ) ) {
                // This must not be the message we're waiting for, so we should bail out.
                // console.log( "Most likely not the token." );
                return;
            } else {
                token = obj.cayuse_login_token;
                if ( 'module_id' in obj ) {
                    // console.log( "Setting module ID." );
                    // Re-enable this if things start to break.
                    // setModuleId( obj.module_id )
                }
            }
        } else {
            // Not a JSON object, so likely our token:
            token = e.data;
        }

        token = token.trim();

        // Trim off "bearer" if that was sent.
        if ( token.indexOf( "Bearer" ) >= 0 )
            token = token.replace( "Bearer", "" ).trimLeft();

        if ( token.indexOf( "bearer" ) >= 0 )
            token = token.replace( "bearer", "" ).trimLeft();

        // Make sure the token is a valid JWT token.
        try {
            let decoded = jwtDecode( token );
        } catch ( err ) {
            console.error( "Invalid token." );
            return;
        }

        // The message received appears to have contained a valid token, so use it.
        setToken( token );
        localStorage.setItem( LOCAL_STORAGE_KEYS.AUTH_TOKEN, token );

        // Send reply to source of message
        e.source.postMessage( 'TOKEN_ACK', e.origin );

        window.location.reload();
    }

    useEffect( () => {
        if ( inIframe() )
            window.addEventListener( 'message', handleMessageFromParent );
        return () => {
            if ( inIframe() )
                window.removeEventListener( 'message', handleMessageFromParent )
        };
    }, [] );

    return (
        <div>{refreshMe}
            <Router>
                <Breadcrumb moduleId={moduleId} />
                { /* <Navigation /> */}
                <div className='page-content'>

                    <Switch p={token}>
                        <Route path="/" exact component={Reports}/>
                        <Route path={"/:moduleId" + PAGE_ROUTES.REPORTS} exact component={Reports}/>
                        <Route path={PAGE_ROUTES.REPORTS} exact component={Reports}/>
                        <Route path={"/:moduleId" + PAGE_ROUTES.REPORTS + "/:reportId"} exact component={ReportViewer}/>
                        <Route path={PAGE_ROUTES.REPORTS + "/:reportId"} exact component={ReportViewer}/>
                        <Route path={PAGE_ROUTES.HANDOFF + "/:moduleId"} component={Handoff}/>
                        <Route path={PAGE_ROUTES.HANDOFF} exact component={Handoff}/>
                        <Route path={PAGE_ROUTES.ONELOGIN} exact component={OneLoginRerouter}/>
                    </Switch>
                    { /* <Footer /> */}
                </div>
            </Router>
            {/*<SilentTokenRefresh></SilentTokenRefresh>*/}
            <div style={{ float: "left", color: "#f5f5f5" }}>
                {/*<span style={{ paddingLeft: "10px" }}>DEBUG:  The moduleId is: {moduleId}</span>&nbsp;*/}
                {/*<span>The token is: {( token ) ? token.substring( 0, 25 ) : ""}...</span>*/}
                <div style={{display:"none"}} className="build-date">Build Date: {preval`module.exports = new Date().toLocaleString();`}.</div>
            </div>
        </div>
    );
}

export default MainView;
