import React from "react";
import PropTypes from "prop-types";
import {Button, Icon, Modal} from "semantic-ui-react";

import styles from "./styles.css";


const ConfirmationModal = ({ cancelButtonLabel, confirmButtonLabel, icon, content, onCancel, onConfirm, title, open }) => {
	const isFunction = value => typeof value === "function";

	const handleConfirm = () => {
		if (isFunction(onConfirm)) {
			onConfirm();
		}
	};

	const handleCancel = () => {
		if (isFunction(onCancel)) {
			onCancel();
		}
	};

	return (
		<Modal
			basic='true'
			dimmer='true'
			open={open}
			onClose={handleConfirm}
			size='small'
			animation='true'
		>
			<Modal.Header className={`ui header ${styles.transparentModalHeader}`}>
				{title}
			</Modal.Header>
			<Modal.Content>
				{icon && <div style={{float:"left"}}>
					<Icon
						style={{color:"white"}}
						aria-hidden="true"
						size="massive"
						name={icon}

					/>
				</div>}
				<div style={{color:"white",display:"inline-block",float:"left"}}>
					{content}
				</div>
			</Modal.Content>

			<Modal.Actions >
				{isFunction(onCancel) && <Button className={styles.transparentModalActionButtonSecondary} onClick={handleCancel}>
					Cancel
				</Button>}
				{isFunction(onConfirm) && <Button className={styles.transparentModalActionButtonPrimary} onClick={handleConfirm}>
					Confirm
				</Button>}
			</Modal.Actions>
		</Modal>
	);
};

ConfirmationModal.propTypes = {
	icon: PropTypes.string,
	message: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.string
	]),
	onCancel: PropTypes.func,
	onConfirm: PropTypes.func,
	title: PropTypes.string,
	visible: PropTypes.bool
};

export default ConfirmationModal;
