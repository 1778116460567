import React, { useState } from 'react';

const TokenContext = React.createContext(["", (() => "")] );

const TokenContextProvider = (props) => {
    const [tokenState, setTokenState] = useState("");
    return (
        <TokenContext.Provider value={[tokenState, setTokenState]}>
            {props.children}
        </TokenContext.Provider>
    );
}

export { TokenContext, TokenContextProvider };
