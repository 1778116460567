import React from "react";
import './App.less';
import MainView from "./components/MainView";
import { ModuleContextProvider} from "./context/ModuleContext";
import { TokenContextProvider} from "./context/TokenContext";
import { AuthProvider } from "./utils/AuthUtils";
import { createBrowserHistory } from "history";

const onRedirectCallback = appState => {
	createBrowserHistory().push(
		appState && appState.targetUrl
			? appState.targetUrl
			: window.location.pathname
	);
};

function App() {
    return (
        <div className="App">
        <ModuleContextProvider>
            <AuthProvider
                redirect_uri={`${window.location.origin}/`}
                onRedirectCallback={onRedirectCallback}
            >
                <TokenContextProvider>

                    <MainView></MainView>

                </TokenContextProvider>
            </AuthProvider>
            </ModuleContextProvider>
        </div>
    );
}

export default App;