import React, { useEffect, useRef, useState } from 'react';
import './ReportViewer.less';

import * as FlexmonsterReact from 'react-flexmonster';
import { Link, useHistory } from "react-router-dom";
import Input, { Button, Icon } from "semantic-ui-react";
import { API_PATHS, LOCAL_STORAGE_KEYS, PAGE_ROUTES } from "../../constants";
import { standardGetFetchOptions, standardHeadFetchOptions, standardPostFetchOptions } from "../../utils/FetchUtils";
import NewReportModal from "../NewReportModal";
import DatePicker from "react-datepicker/dist/react-datepicker";
import { TextArea } from "semantic-ui-react";
import { getModuleIdFromPath, has } from "../../utils/GeneralUtils";
import SaveReportModal from "../SaveReportModal";
import ConfirmationModal from "../ConfirmationModal";

function ReportViewer( props ) {
    const { data } = props.location;

    let reportData = data;
    // if ( reportData )
    //     localStorage.setItem( "reportViewerData", JSON.stringify( reportData ) );
    // else
    //     reportData = JSON.parse( localStorage.getItem( "reportViewerData" ) );

    const [ clickCount, setClickCount ] = useState( 1 );
    const [ reportBody, setReportBody ] = useState( reportData ? reportData.reportBody : null );
    const [ isReadyToLoad, setIsReadyToLoad ] = useState( true );
    const [ overwriteFilename,setOverwriteFilename ] = useState( "" );

    const pivotRef  = useRef( "pivot" );

    let moduleId = getModuleIdFromPath();


    // console.log( "reportData:" );
    // console.log( reportData );

    var initialFilterValue = [];

    const setupFilters = function() {
        // var dataset = reportData.dataset;
        if ( reportData && reportData.dataset ) {
            let dataset = reportData.dataset;
            // console.log( "dataset is defined." );
            if ( dataset.filters ) {
                // console.log( "Dataset and Filters are defined." )
                let filters = dataset.filters;
                for ( let i = 0; i < filters.length; i++ ) {
                    let filter = filters[ i ];

                    // If the filters passed in don't have values already, set up the defaults.
                    if ( !filter.filterValue ) {
                        let defaultFunc = new Function( filter.defaultValue );
                        let defaultValue = defaultFunc();
                        initialFilterValue[ i ] = defaultValue;

                        // console.log( "defaultValue: " + defaultValue );

                        filter.filterValue = defaultValue; //new Date( initialFilterValue ).toISOString().substring( 0, 10 );

                    } else {
                        initialFilterValue[ i ] = filter.filterValue;
                    }
                }
            }
        }
    }
    const [ filterValues, setFilterValues ] = useState( initialFilterValue );
    
    function getQueryStringFromFilters( filters ) {
        if( !filters || filters.length === 0 ) {
            // Append the current time to avoid cache.
            return "?" + ( Math.trunc( new Date().getTime() / 1000 ) );
        }

        let queryString = "?";

        for ( let i = 0; i < filters.length; i++ ) {
            let f = filters[ i ];
            if ( i > 0 )
                queryString += "&";

            queryString += f.fieldName;

            if ( f.filterValue ) {
                queryString += "=";

                if( f.type === 'Date' ) {
                    queryString += f.filterValue.toISOString().substring( 0, 10 );
                } else {
                    queryString += f.filterValue;
                }
            }
        }

        queryString += "&" + ( Math.trunc( new Date().getTime() / 1000 ) );

        return queryString;
    }

    const refreshReportData = function ( reportBodyParam ) {
        let flexmonster = pivotRef.current.flexmonster;
        if( !flexmonster )
            return;

        // console.log("Refreshing report.");
        // console.log( reportBody );
        // console.log( reportBodyParam );
        if(! ( reportBody || reportBodyParam ) )
            return;

        let queryString = getQueryStringFromFilters( reportData.dataset.filters );

        let flexmonsterReport = flexmonster.getReport();

        if( ! ( flexmonsterReport && flexmonsterReport.dataSource ) ) {
            // console.log( "report is null" );
            if( !reportBody ) {
                // console.log( "reportBody is null." );
                return;
            }
            flexmonsterReport = reportBody;

            if( !flexmonsterReport )
                flexmonsterReport = reportBodyParam;
        }

        // console.log( "Going to set up datasource." );

        // console.log( "report is: ");
        // console.log( reportData );
        let dataSource = {
            requestHeaders: {
                "Authorization": "Bearer " + localStorage.getItem( LOCAL_STORAGE_KEYS.AUTH_TOKEN )
            },
            filename: process.env.REACT_APP_CONNECTOR_HOST + reportData.dataset.path + queryString,
            // need to retain any datasource mapping definition in order to avoid runtime errors regarding the datatype of aggregation results
            mapping: flexmonsterReport.dataSource.mapping
        }

        // report.dataSource.requestHeaders = {
        //    "Authorization": "Bearer " + localStorage.getItem( LOCAL_STORAGE_KEYS.AUTH_TOKEN )
        // };
        // report.dataSource.filename = process.env.REACT_APP_CONNECTOR_HOST + dataset.path + queryString;
        // console.log( flexmonsterReport.dataSource.filename );

        flexmonsterReport.dataSource = dataSource;

        flexmonster.setReport( flexmonsterReport );
    };


    if ( reportData ) {
        // console.log( " Report data was passed in. " );
        setupFilters();
        if ( reportData.reportBody ) {
            // console.log(" !!! This report had a body !!! ");

            // refreshReportData();
            // let queryString = getQueryStringFromFilters( reportData.dataset.filters );
            //
            // reportData.reportBody.dataSource.requestHeaders = {
            //     "Authorization": "Bearer " + localStorage.getItem( LOCAL_STORAGE_KEYS.AUTH_TOKEN )
            // };
            // reportData.reportBody.dataSource.filename = process.env.REACT_APP_CONNECTOR_HOST + dataset.path + queryString;
        }
    }



    const handleNameClick = function ( event ) {
        // console.log( clickCount );
        setClickCount( clickCount + 1 );
        if ( !( clickCount % 10 ) ) {
            // alert( "Ten clicks." );
            let showExtraToolTabs = localStorage.getItem( "extraToolTabs" );
            if ( !showExtraToolTabs || showExtraToolTabs !== 'true' )
                localStorage.setItem( "extraToolTabs", true );
            else
                localStorage.setItem( "extraToolTabs", false );
        }
    }

    function fetchReportBody() {
        let reportPath = process.env.REACT_APP_CONNECTOR_HOST + API_PATHS.REPORTS + "/" + reportData.reportId;

        // console.log( "Report Path:" + reportPath );

        fetch( reportPath, standardGetFetchOptions() )
            .then( res => {
                //return res.text();
                //console.log( "response is:" + res.text() )
                return res.json();
            } )
            .then( result => {
                    // console.log( "Results loaded." );
                    // console.log( result );

                    delete result.datasource;

                    setReportBody( result );
                    setupFilters();
                    // refreshReportData( result );
                    // let flexmonster = pivotRef.current.flexmonster;
                    // result.dataSource.requestHeaders = {
                    //     "Authorization": "Bearer " + localStorage.getItem( LOCAL_STORAGE_KEYS.AUTH_TOKEN )
                    // };
                    //
                    // let queryString = getQueryStringFromFilters( reportData.dataset.filters );
                    // console.log( result );
                    // delete result.dataSource.filename;  // = "";
                    // console.log( result );
                    // flexmonster.setReport( result );
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                ( error ) => {
                    console.log( error );
                    setReportBody( null );
                    //setError(error);
                }
            );
    }

    const handleSaveClick = function() {
        setShowSaveModal( true );
    }

    const handleNameModalSave = function( reportName, skipExistCheck ) {
        // console.log( reportName + "<-- report name")

        if( !skipExistCheck ) {
            let fullPath = process.env.REACT_APP_CONNECTOR_HOST + PAGE_ROUTES.REPORTS + "/" + reportName + "?moduleId=" + getModuleIdFromPath();

            let options = standardHeadFetchOptions();

            fetch( fullPath, options )
                .then(res=> {
                    if ( res.status === 404 ) {
                        // console.log( "404 - means report with that name doesn't already exist" )
                        handleNameModalSave( reportName, true );
                    } else if( res.status === 200 ) {
                        // console.log( "200 - means this report name already exists.  overwrite?")
                        setOverwriteFilename( reportName );
                    } else {
                        console.log( "Error saving report." );
                    }

                });

            return;
        }

        // Clear overwrite filename modal if open.
        setOverwriteFilename( "" );

        reportData.reportName = reportName;
        let flexmonster = pivotRef.current.flexmonster;
        //let reportName = prompt( "Report Name: ", "" );

        let reportBody = flexmonster.getReport()

        let reportInfoToSend = JSON.parse( JSON.stringify( reportData ) );
        reportInfoToSend.reportBody = JSON.stringify( reportBody );

        reportInfoToSend.reportName = reportName;
        reportInfoToSend.moduleId = localStorage.getItem( LOCAL_STORAGE_KEYS.MODULE_ID );

        let fullPath = process.env.REACT_APP_CONNECTOR_HOST + PAGE_ROUTES.REPORTS;

        let options = standardPostFetchOptions();
        options.headers["Content-Type"] = "application/json";
        options.body = JSON.stringify( reportInfoToSend );

        fetch( fullPath, options )
            .then(res=> {
                if( res.ok )
                    setShowSaveModal( false );
                else
                    console.log( "Error saving report." );
            })
        ;
    }

    // console.log( props.match.params.id );

    const[ showNewReport, setShowNewReportModal ] = useState( false );
    const[ showSaveModal, setShowSaveModal] = useState( false );
    const[ reportFilename, setReportFilename ] = useState( reportData ? reportData.filename : "" );

    const handleNewReport = function () {
        setShowNewReportModal( true );
    };

    // const refreshReport = function ( reportBodyParam ) {
    //     console.log("Refreshing report.");
    //     console.log( reportBody );
    //     console.log( reportBodyParam );
    //     if(! ( reportBody || reportBodyParam ) )
    //         return;
    //
    //     let queryString = getQueryStringFromFilters( reportData.dataset.filters );
    //     let flexmonster = pivotRef.current.flexmonster;
    //     let report = flexmonster.getReport();
    //
    //     if( ! ( report && report.dataSource ) ) {
    //         console.log( "report is null" );
    //         if( !reportBody ) {
    //             console.log( "reportBody is null." );
    //             return;
    //         }
    //         report = reportBody;
    //
    //         if( !report )
    //             report = reportBodyParam;
    //     }
    //     report.dataSource.requestHeaders = {
    //         "Authorization": "Bearer " + localStorage.getItem( LOCAL_STORAGE_KEYS.AUTH_TOKEN )
    //     };
    //     //delete report.dataSource.filename;
    //     report.dataSource.filename = process.env.REACT_APP_CONNECTOR_HOST + dataset.path + queryString;
    //     console.log( report.dataSource.filename );
    //     flexmonster.setReport( report );
    // };

    const handleNewReportClose = function () {
        setShowNewReportModal( false );
    };

    let history = useHistory();

    const handleOnGo = function ( data ) {
        // console.log( "handleOnGo called with data: " + JSON.stringify( data ) );
        setShowNewReportModal( false );

        history.push( {
            pathname: PAGE_ROUTES.REPORTS + "/" + "New Report",
            data: data
        } );
    };

    const handleSaveModalClose = function (  ) {
        // console.log( "handleSaveModalClose called." );
        setShowSaveModal( false );
    };

    const handleDateFilterChanged = function ( date, filterItem, idx, e ) {
        // Because the date pickers are wrapped in a semantic-ui label, we have to "preventDefault()" so that
        // the click event does not trigger a re-open.  Default behavior of the datapicker is to close the popup
        // after the date has been selected.
        e.preventDefault();

        // console.log( date );
        // console.log( filterItem );
        //filterItem.filterValue = date.toISOString().substring( 0, 10 );
        filterItem.filterValue = date;
        // console.log( filterItem );
        var newArray = [ ...filterValues ];
        newArray[ idx ] = date;
        setFilterValues( newArray );
    }

    const handleTextFilterChanged = function( data, filterItem, idx ) {
        filterItem.filterValue = data.target.value;

        var newArray = [ ...filterValues ];
        newArray[ idx ] = data.target.value;
        setFilterValues( newArray );
    }

    useEffect(()=>{
        console.log( "using effect" );
        if( !reportBody ) {
            console.log( "reportBody is null" );
            if( fetchReportBody === null )
            {
                console.log( "fetchReportBody is null" );
            }
            fetchReportBody();
        }
       // refreshReport();
    },[]);

    return (
        <div className="ReportViewer">
            <div className="header">
                <div className="names">
                    {reportData &&
                    <div className="report-name" onClick={handleNameClick}>{reportData.reportName}</div>
                    }
                    {/*{reportData && reportData.dataset &&*/}
                    {/*<div className="dataset-name" onClick={handleNameClick}>{reportData.dataset.descriptiveName}</div>*/}
                    {/*}*/}
                </div>
                {reportData.dataset && reportData.dataset.filters &&
                        reportData.dataset.filters.map( ( filter, idx ) =>
                            <div key={idx} className="filter-and-label-block" >
                                <div className="filter-label">{filter.descriptiveName}<span style={{color:"#b31200"}}>*</span></div>
                                <div className="filter-values-block">
                                {filter.type === "Date" &&
                                <label className="date-picker-with-icon" >
                                    <div className="date-picker-icon" ><Icon name="calendar alternate outline"/></div>
                                    <div className="date-picker-alignment"><DatePicker onChange={ ( date, e ) => handleDateFilterChanged( date, filter, idx, e )}
                                                selected={filterValues[ idx ]} />
                                    </div>

                                </label>
                                }

                                {filter.type === "Text" &&
                                <input type="text"  placeholder={"Enter " + filter.descriptiveName} onChange={ ( e, data ) => handleTextFilterChanged( e, filter, idx )}
                                       value={filterValues[ idx ] } ></input>
                                }
                                </div>
                            </div>
                        )
                    // </div>
                }
                <Button  disabled={!isReadyToLoad} primary={isReadyToLoad} onClick={refreshReportData} style={{float:"left", marginTop:"0.15em"}}><Icon name='refresh' style={{marginRight:"0px",marginLeft:"0px"}} ></Icon></Button>
                <div className="button-block" style={{float:"right",marginTop:"0.15em"}} >
                    <Button primary onClick={handleSaveClick} style={{float:"right"}}>Save</Button>
                    <Button primary onClick={handleNewReport} style={{float:"right"}} ><Icon name='plus'></Icon>New Report</Button>
                </div>
            </div>

            {reportData == null
                ? <div><h1></h1></div>
                : <FlexmonsterReact.Pivot
                    ref={pivotRef}
                    //report={reportBody}
                    toolbar={true}
                    beforetoolbarcreated={toolbar => {
                        var tabs = toolbar.getTabs();
                        toolbar.getTabs = function () {
                            let showExtraToolTabs = localStorage.getItem( "extraToolTabs" );
                            if ( !showExtraToolTabs || showExtraToolTabs != 'true' ) {
                                delete tabs[ 2 ];
                                delete tabs[ 1 ];
                                delete tabs[ 0 ];

                                delete tabs[ tabs.length - 1 ];
                                delete tabs[ tabs.length - 1 ];
                                delete tabs[ tabs.length - 1 ];
                            }

                            return tabs;
                        };
                        console.log( "beforetoolbarcreated" )
                    }
                    }
                    componentFolder="https://cdn.flexmonster.com/" width="100%"
                    licenseKey={process.env.REACT_APP_FM_LICENSE}
                />
            }

            { showNewReport &&
            <NewReportModal open={showNewReport} onClose={handleNewReportClose} onGo={handleOnGo} />
            }
            { showSaveModal &&
            <SaveReportModal  suggestedFileName={reportFilename} open={showSaveModal} onCloseClicked={handleSaveModalClose} onGo={(f)=> { handleNameModalSave(f);} } />
            }
            { overwriteFilename &&
                <ConfirmationModal
                    icon={"exclamation triangle"}
                    style={{ modal: {} }}
                    open={overwriteFilename}
                    onCancel={ ()=> setOverwriteFilename( "" ) }
                    onConfirm={ ()=> handleNameModalSave( overwriteFilename, true ) }
                    title={"A report with this name already exists."}
                    content={"Do you want to continue and overwrite the existing report?"}
                />
            }
        </div> );
}

export default ReportViewer;
