import {LOCAL_STORAGE_KEYS} from "../constants";


export function standardGetFetchOptions() {
    let standardOptions =
    {
            method: 'GET',
            headers: getStandardHeaders()
    }

    return standardOptions;
}

export function standardHeadFetchOptions() {
    let standardOptions =
        {
            method: 'HEAD',
            headers: getStandardHeaders()
        }

    return standardOptions;
}

export function standardDeleteFetchOptions() {
    let standardOptions =
        {
            method: 'DELETE',
            headers: getStandardHeaders()
        }

    return standardOptions;
}

export function standardPutFetchOptions() {
    let standardOptions =
        {
            method: 'PUT',
            headers: getStandardHeaders()
        }

    return standardOptions;
}

export function standardPostFetchOptions() {
    let standardOptions =
        {
            method: 'POST',
            headers: getStandardHeaders()
        }

    return standardOptions;
}

export function getStandardHeaders() {
    let token = localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);

    let standardHeaders = {
        'Accept': 'application/json',
        'Authorization': "Bearer " + token
    };

    return standardHeaders;
}
