import React, { useEffect, useState } from "react";
import './ReportsList.less';
import { Link } from "react-router-dom";
import { Icon, Popup, Table, TableBody } from "semantic-ui-react";
import ConfirmationModal from "../ConfirmationModal";
// import {useStore} from "react-context-hook";
import { API_PATHS, LOCAL_STORAGE_KEYS, PAGE_ROUTES } from "../../constants";
// import {ModuleContext} from "../../context/ModuleContext";
import { standardDeleteFetchOptions, standardGetFetchOptions } from "../../utils/FetchUtils";
import { getModuleIdFromPath } from "../../utils/GeneralUtils";


function ReportsList() {

    const moduleId = getModuleIdFromPath();
    // console.log( "ReportsList moduleId: " + moduleId );

    const initialDataItems = [ {
        reportName: "Loading...",
        createdBy: "",
        lastSaved: "",
        dataset: "",
        reportId: "",
        fullReportData: null
    } ];

    const [ error, setError ] = useState( null );
    // eslint-disable-next-line no-unused-vars
    const [ isLoaded, setIsLoaded ] = useState( false );
    const [ displayItems, setDisplayItems ] = useState( initialDataItems );

    //const [moduleId, setModuleId] = useContext( ModuleContext );

    // eslint-disable-next-line no-unused-vars
    // const [ moduleId, setModuleId ] = useState( localStorage.getItem( LOCAL_STORAGE_KEYS.MODULE_ID ) );
    //console.log( "moduleState:" + JSON.stringify( moduleId ) );

    const [ state, setState ] = useState( { confirmingDelete: false, reportToDelete: null } );

    let openDeleteConfirm = ( report ) => setState( { confirmingDelete: true, reportToDelete: report } );
    let closeDeleteConfirm = () => setState( { confirmingDelete: false, reportToDelete: null } );

    const promptToDelete = function ( report ) {
        //console.log( "Confirming deletion of " + report.reportName );

        openDeleteConfirm( report );
    }

    const handleDelete = function () {
        // alert("Would have done delete.");
        //console.log( "Handling delete." );
        executeDeleteReport( state.reportToDelete );
        closeDeleteConfirm();
    };

    const inlineStyle = {
        modal: {
        }
    };

    const formatDateIfAble = function ( dateString, defaultVal ) {
        if ( defaultVal === undefined )
            defaultVal = "";

        try {
            let date = new Date( dateString );
            // console.log( date );
            return new Intl.DateTimeFormat( 'en-US', {
                month: "numeric",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric"
            } ).format( date );
        } catch ( err ) {
            return defaultVal;
        }
    }

    const executeDeleteReport = function ( report ) {
        // console.log( report );
        let fullPath = process.env.REACT_APP_CONNECTOR_HOST + API_PATHS.REPORTS + "/" + report.reportId;
        // console.log( "Calling DELETE on " + fullPath );

        fetch( fullPath, standardDeleteFetchOptions() ).then(
            ( result ) => {
                setIsLoaded( false );
                return true;
            },
            ( error ) => {
                console.log( "Error trying to delete report." );
            }
        ).then(
            ( status ) => {
                fetchReports();
            }
        );
    }

    function fetchReports() {
        let params = '?moduleId=' + moduleId;
        let fullPath = process.env.REACT_APP_CONNECTOR_HOST + API_PATHS.REPORTS + params;

        fetch( fullPath, standardGetFetchOptions()
        )
            .then( ( res ) => {
                if( res.status === 401 )
                    throw new Error( "User is not authenticated.  Please refresh page." );

                return res.json();
            } )
            .then(
                ( result ) => {
                    // console.log( "Results loaded." );
                    // console.log( result );
                    setIsLoaded( true );
                    var displayItems = result.map( ( item, key ) => {
                        return {
                            reportName: item.reportName,
                            reportId: item.reportId,
                            createdByName: item.createdByName,
                            dataset: item.dataset.descriptiveName,
                            canDelete: item.canDelete,
                            lastSaved: formatDateIfAble( item.lastSaved, "" ),
                            fullReportData: item
                        };
                    } );

                    setDisplayItems( displayItems );
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                ( error ) => {
                    console.log( error );
                    setIsLoaded( true );
                    setError( error );
                }
            )
    }

    // useEffect(() => {
    //     fetchReports();
    // }, [moduleId]);

// similar to componentDidMount()
    useEffect( fetchReports, [] );

    if ( error ) {
        console.error( "Error." );
        return <div>{error.message}</div>;
        // } else if (!isLoaded) {
        //     return <div>Loading...</div>;
    } else {
        return (
            <div className="ReportsList">

                <div className="contents">
                    <div className="reportList">
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Report Name</Table.HeaderCell>
                                    <Table.HeaderCell>Created By</Table.HeaderCell>
                                    <Table.HeaderCell>Data Set</Table.HeaderCell>
                                    <Table.HeaderCell>Last Saved</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>

                                </Table.Row>
                            </Table.Header>
                            <TableBody>
                                {displayItems.map( ( item, key ) =>
                                        // <Link to={ "/reports/" + item.reportName }>
                                        <Table.Row key={item.reportKey}>
                                            <Table.Cell><Link to={{
                                                pathname: "/" + moduleId + PAGE_ROUTES.REPORTS + "/" + item.reportId,
                                                data: item.fullReportData
                                            }}>{item.reportName}</Link></Table.Cell>
                                            <Table.Cell>{item.createdByName}</Table.Cell>
                                            <Table.Cell>{item.dataset}</Table.Cell>
                                            {
                                                <Table.Cell>{item.lastSaved}</Table.Cell>}
                                            <Table.Cell textAlign='center'>
                                                {item.canDelete &&
                                                <div>
                                                    <Popup content='Unlocked - this report can be deleted and overwritten.'
                                                           basic
                                                           trigger={<Icon style={{ cursor: "pointer" }} color='blue'
                                                                          name="unlock alternate"/>
                                                           }/>
                                                    <Popup content='Delete' basic
                                                           trigger={<Icon style={{ cursor: "pointer" }} color='blue'
                                                                          name="trash alternate outline"
                                                                          onClick={() => promptToDelete( item.fullReportData )}/>
                                                           }/>
                                                </div>
                                                }
                                                {!item.canDelete &&
                                                <div>
                                                    <Popup
                                                        content='Locked - This report is read only and cannot be deleted or overwritten'
                                                        basic
                                                        trigger={<Icon style={{/*cursor: "pointer"*/ }} color='blue'
                                                                       name="lock"/>
                                                        }/>
                                                </div>
                                                }
                                            </Table.Cell>
                                        </Table.Row>
                                    // </Link>
                                )}
                            </TableBody>

                        </Table>

                    </div>
                    {/*<span style={{ color: "lightgray" }}>&nbsp;&nbsp;debug: {moduleId}</span>*/}
                </div>
                {
                    state.reportToDelete &&
                    <ConfirmationModal
                        icon={"trash alternate outline"}
                        style={inlineStyle}
                        open={state.confirmingDelete}
                        onCancel={closeDeleteConfirm}
                        onConfirm={handleDelete}
                        title={"Delete Report?"}
                        content={"Are you sure you want to delete the report named " + state.reportToDelete.reportName + "?"}
                    />
                }
            </div> );
    }
}

export default ReportsList;
